import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
  selectIsAuthenticated,
  selectIsShowMfa,
  selectMfaVerified,
  selectUser,
} from '../../features/auth/authSlice';
import {
  canAddOrEditAccounts,
  canViewAccounts,
  canViewAuditLog,
  canViewOrAddUser,
  canViewSites,
  canViewUsageReport,
  canAddEditSsoClient,
  canViewExportPage,
  canViewImportPatientPage,
  canViewManagementReport,
  canViewDataStructuredPage,
  canAddEditTemplate,
  canViewAccountSettingsPage,
  canViewRescoreInterviewPage,
  canViewBuilder,
  canViewMasterUsageReport,
  isQuickSightReportEnabled,
  canViewClinicalFollowupsPage,
} from '../utils/permissionUtils';
import { canAddSite } from '../utils/permissionUtils';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const authenticated = useAppSelector(selectIsAuthenticated);
  const isShowMfa = useAppSelector(selectIsShowMfa);
  const mfaVerified = useAppSelector(selectMfaVerified);
  const currentUser = useAppSelector(selectUser);
  let location = useLocation();

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (isShowMfa && !mfaVerified) {
    const mfaPath = ['/mfa', '/mfa/'];
    if (mfaPath.includes(location.pathname)) {
      return children;
    }
    return <Navigate to="/mfa" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/unlock' ||
      children.props.path === '/dashboard/users' ||
      children.props.path === '/dashboard/users/add-user') &&
    !canViewOrAddUser(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (children.props.path === '/dashboard/sites' && !canViewSites(currentUser.roleId)) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/sites/add-site' &&
    !canAddSite(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/sites/add-site' &&
    !canAddSite(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/ssoClients' ||
      children.props.path === '/dashboard/ssoClients/add-sso-client' ||
      children.props.path === '/dashboard/ssoClients/edit-sso-client') &&
    !canAddEditSsoClient(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/accounts' &&
    !canViewAccounts(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/accounts/add-account' ||
      children.props.path === '/dashboard/accounts/edit-account/:accountId') &&
    !canAddOrEditAccounts(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/sites/add-site' &&
    !canAddSite(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/report' &&
    !canViewUsageReport(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/master-usage-report' &&
    !canViewMasterUsageReport(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/audit-log' &&
    !canViewAuditLog(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/raw-export' ||
      children.props.path === '/dashboard/structured-export') &&
    !canViewExportPage(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/patients/import-patient' &&
    !canViewImportPatientPage(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/management-report' &&
    !canViewManagementReport(currentUser.roleId, currentUser.showManagementReport)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/structured-export' ||
      children.props.path === '/dashboard/structured-export/structured-data-codebook' ||
      children.props.path ===
        '/dashboard/structured-export/structured-data-codebook/detail/:interviewType' ||
      children.props.path === '/dashboard/structured-export/structured-scored-data-codebook' ||
      children.props.path ===
        '/dashboard/structured-export/structured-scored-data-codebook/detail/:interviewType') &&
    !canViewDataStructuredPage(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    children.props.path === '/dashboard/account-messaging' &&
    !canViewAccountSettingsPage(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/rescore-jobs' ||
      children.props.path === '/dashboard/import-interviews') &&
    !canViewRescoreInterviewPage(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/templates/add-template' ||
      children.props.path === '/dashboard/templates/edit-template/:templateId') &&
    !canAddEditTemplate(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/assessment-builder' ||
      children.props.path ===
        '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types/edit/:assessmentTypeId' ||
      children.props.path ===
        '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types/add' ||
      children.props.path ===
        '/dashboard/assessment-builder/interview/:assessmentId/interview-types/edit/:assessmentTypeId' ||
      children.props.path ===
        '/dashboard/assessment-builder/interview/:assessmentId/interview-types/add' ||
      children.props.path ===
        '/dashboard/assessment-builder/interview/:assessmentId/interview-types' ||
      children.props.path ===
        '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types') &&
    !canViewBuilder(currentUser.roleId)
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if (
    (children.props.path === '/dashboard/patient/:patientId/patient-report' ||
      children.props.path === '/dashboard/management-report') &&
    !isQuickSightReportEnabled
  ) {
    return <Navigate to="*" state={{ from: location }} />;
  } else if ((children.props.path === '/dashboard/accounts/clinical-follow-ups/:clinicalAccountId'
  ) && !canViewClinicalFollowupsPage(currentUser.roleId)) {
    return <Navigate to="*" state={{ from: location }} />;
  }

  return children;
}
