import { SortDirs } from "./sortInfo";

export const DefaultPageSize = 10;

export const DefaultSearchPageSize = 50; // will show how many items on the dropdown\

export const DefaultRemovedDays = 180;

export const AppConfig = {
  version: process.env.REACT_APP_VERSION || 'dev',
};

export const obfuscatePhoneNo = (phoneNumber: string) => {
  phoneNumber = phoneNumber || '';
  const lastPreveredDigitCount = 4;
  if (phoneNumber.length <= lastPreveredDigitCount) {
    return phoneNumber;
  }

  return '***' + phoneNumber.slice(phoneNumber.length - lastPreveredDigitCount);
};

export const obfuscateEmail = (email: string) => {
  if (!email) {
    return '';
  }

  const skipFirstChars = 3;
  let domainIndexStart = email.lastIndexOf('@'),
    emailName = email.slice(0, domainIndexStart),
    firstThreeChar = emailName.slice(0, skipFirstChars),
    maskedEmail = '';
  if (domainIndexStart > skipFirstChars) {
    maskedEmail = emailName.slice(skipFirstChars, domainIndexStart);
    maskedEmail = maskedEmail.replace(/./g, '*');
  } else {
    firstThreeChar = '*'.repeat(firstThreeChar.length);
  }
  const domain = email.slice(domainIndexStart, email.length);
  return firstThreeChar.concat(maskedEmail).concat(domain);
};

export enum Status {
  ACTIVE = 'active',
  DEACTIVATED = 'inactive',
  DELETED = 'deleted',
}

export const filterRequest = (request: any, keys: any) => keys.reduce((result: any, key: any) => ({ ...result, [key]: request[key] }), {});

export const sortItemsOnList = (listItems: any[], sortOptions: { sortField: string, sortDir: SortDirs }) => {
  const sort1 = sortOptions.sortDir === SortDirs.Asc ? 1 : -1;
  const sort2 = sortOptions.sortDir === SortDirs.Asc ? -1 : 1;

  listItems.sort((item1, item2) => {
    if (item1[sortOptions.sortField] === item2[sortOptions.sortField]) {
      return 0;
    }
    return item1[sortOptions.sortField] > item2[sortOptions.sortField] ? sort1 : sort2;
  });

  return listItems;
}

export const DeletePdfFileConstants = {
  AwaitingTimeDeletePdfFile: 5 * 60 * 1000, // 5'
};
