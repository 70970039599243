import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { getTotalTime } from '../../../common/utils/dateTimeUtils';
import { LineItemScores } from '../../../common/utils/reportTypes';
import { newlinesToBr } from '../../../common/utils/reportUtils';
import { Assessment, AssessmentResult, Interviewer, Patient } from '../../../types/ReportType';
import { InterviewReportType } from '../../interview/interviewUtils';

interface ReportSummaryPageProps {
  assessmentResult?: AssessmentResult;
  patient?: Patient;
  interview?: Assessment;
  interviewer?: Interviewer;
  moduleQuestions?: any;
}

export default function ReportSummaryPage({
  assessmentResult,
  patient,
  interview,
  interviewer,
  moduleQuestions,
}: ReportSummaryPageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isPHQ =
    interview?.reportType === InterviewReportType.Screener &&
    interview.subReportType === InterviewReportType.PHQ;

  const isMINI = interview?.reportType === InterviewReportType.MINI;
  const isSDS = interview?.reportType === InterviewReportType.SdsSts;

  const patientNameTitle = patient
    ? patient.lastName
      ? t('assessmentReport.labels.patientName')
      : patient.externalId
      ? t('assessmentReport.labels.patientExternalId')
      : ''
    : '';

  const patientName = patient
    ? patient.lastName
      ? patient.lastName + ', ' + patient.firstName
      : patient.externalId
      ? patient.externalId
      : ''
    : '';

  const hasReasonForEdit = !!assessmentResult?.reasonForEdit;
  let startedAtDisplay = '';
  if (
    assessmentResult &&
    patient &&
    typeof assessmentResult.startedAt !== 'undefined' &&
    !!assessmentResult.startedAt
  ) {
    startedAtDisplay = dayjs(assessmentResult.startedAt)
      .tz(patient.timezone)
      .format('MM/DD/YYYY h:mm:ss a');
  }

  let completedAtDisplay = '';
  if (
    assessmentResult &&
    patient &&
    typeof assessmentResult.completedAt !== 'undefined' &&
    !!assessmentResult.completedAt
  ) {
    completedAtDisplay = dayjs(assessmentResult.completedAt)
      .tz(patient.timezone)
      .format('MM/DD/YYYY h:mm:ss a');
  }

  const totalTime = getTotalTime(assessmentResult?.startedAt, assessmentResult?.completedAt);

  let interviewerName = '';
  if (assessmentResult?.editingInterviewerName) {
    interviewerName = assessmentResult?.editingInterviewerName;
  } else if (assessmentResult?.interviewerName) {
    interviewerName = assessmentResult?.interviewerName;
  } else {
    interviewerName = interviewer?.lastName + ' ' + interviewer?.firstName;
  }

  let parsedNotes = '';
  if (assessmentResult && assessmentResult.reasonForEdit) {
    parsedNotes = newlinesToBr(assessmentResult.reasonForEdit);
  }

  const labelValue = (label: string, value: string, sx: any = {}) => {
    return (
      <Typography component="div" sx={{ display: 'flex', ...sx }}>
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
        :&nbsp;
        <Typography component="span">{value}</Typography>
      </Typography>
    );
  };

  const CustomAnchor = styled('a')(() => ({
    color: theme.palette.primary.main,
  }));

  const ReportCheckbox = styled('div')(() => ({
    border: '1px solid #dddddd',
    height: '16px',
    width: '16px',
    display: 'inline-block',
    position: 'relative',
    top: '2px',
    marginRight: '0.5rem',
  }));

  const ReportCheckboxUnfilled = styled(ReportCheckbox)(() => ({
    backgroundColor: '#fff',
  }));

  const ReportCheckboxFilled = styled(ReportCheckbox)(() => ({
    backgroundColor: '#000',
  }));

  const ReportCheckboxPartialFill = styled(ReportCheckbox)(() => ({
    backgroundColor: '#ababab',
  }));

  const TableCellStyled = styled(TableCell)(() => ({
    fontSize: '16px',
    padding: '2px 6px',
    textAlign: 'left',
    pageBreakInside: 'avoid',
    border: 'none',
  }));

  const TableCellHeadStyled = styled(TableCellStyled)(() => ({
    fontWeight: 'bold',
  }));

  const renderTableRow = (
    numLineItemsAddedForModule: number,
    numLineItemsAddedForSection: number,
    moduleLetter: string,
    scoringSectionTitle: string,
    scoringLineItem: any
  ) => {
    let lineItemTitle = scoringLineItem.title;
    if (!!scoringLineItem.summaryTitle) {
      lineItemTitle = scoringLineItem.summaryTitle;
    }
    if (!!scoringLineItem.timeframe) {
      lineItemTitle += ' (' + scoringLineItem.timeframe + ')';
    }

    let ScoringCheckBox = ReportCheckboxUnfilled;
    const lineItemScore = scoringLineItem.lineItemScore;
    if (typeof lineItemScore !== 'undefined' && !!lineItemScore) {
      if (lineItemScore === LineItemScores.Met) {
        ScoringCheckBox = ReportCheckboxFilled;
      } else if (lineItemScore === LineItemScores.Na) {
        ScoringCheckBox = ReportCheckboxPartialFill;
      }
    }

    let PrimaryDiagnosisCheckbox = ReportCheckboxUnfilled;
    if (assessmentResult && assessmentResult.primaryDiagnosis === scoringLineItem.id) {
      PrimaryDiagnosisCheckbox = ReportCheckboxFilled;
    }

    return (
      <TableRow key={scoringLineItem.id}>
        <TableCellStyled>
          {numLineItemsAddedForModule === 0 && (
            <CustomAnchor href={`#letter-${moduleLetter}`}>{moduleLetter}</CustomAnchor>
          )}
        </TableCellStyled>

        <TableCellStyled>
          {numLineItemsAddedForSection === 0 && scoringSectionTitle}
        </TableCellStyled>

        <TableCellStyled>{lineItemTitle}</TableCellStyled>

        <TableCellStyled>
          <ScoringCheckBox />
        </TableCellStyled>

        <TableCellStyled>{scoringLineItem.icd10CmCode || ''}</TableCellStyled>

        <TableCellStyled>
          <PrimaryDiagnosisCheckbox />
        </TableCellStyled>
      </TableRow>
    );
  };

  const getTableRows = () => {
    const tableRows: any = [];
    let numLineItemsAddedForModule = 0;
    let numLineItemsAddedForSection = 0;

    if (!moduleQuestions?.length) {
      return [];
    }

    for (let i = 0; i < moduleQuestions.length; i++) {
      numLineItemsAddedForModule = 0;
      const moduleQuestion = moduleQuestions[i];
      for (let j = 0; j < moduleQuestion.questionScores.length; j++) {
        numLineItemsAddedForSection = 0;
        const questionScore = moduleQuestion.questionScores[j];
        for (let k = 0; k < questionScore.interviewModuleScoringLineItems.length; k++) {
          const scoringLineItem = questionScore.interviewModuleScoringLineItems[k];
          if (!scoringLineItem.hideFromSummary) {
            tableRows.push(
              renderTableRow(
                numLineItemsAddedForModule,
                numLineItemsAddedForSection,
                moduleQuestion.letter,
                questionScore.title,
                scoringLineItem
              )
            );
            numLineItemsAddedForSection += 1;
            numLineItemsAddedForModule += 1;
          }
        }
      }
    }
    return tableRows;
  };

  return (
    <Box component="div">
      {((isPHQ && hasReasonForEdit) || !isPHQ) && (
        <Box
          component="div"
          className="interviewInfo"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: theme.xPalette.lightGreyBackground,
            padding: '10px',
            border: '1px solid #000',
            margin: '0 0 30px 0',
            overflow: 'hidden',
            pageBreakInside: 'avoid',
          }}
        >
          {!isSDS && (
            <>
              <Box component="div" sx={{ display: 'flex', width: '49%', flexWrap: 'wrap' }}>
                {labelValue(patientNameTitle, patientName, { width: '100%', display: 'block' })}
                {!hasReasonForEdit && (
                  <>
                    {labelValue(t('assessmentReport.labels.totalTime'), totalTime, {
                      width: '100%',
                      display: 'block',
                    })}
                  </>
                )}
              </Box>
              <Box component="div" sx={{ display: 'flex', width: '49%', flexWrap: 'wrap' }}>
                {patient &&
                  patient.id &&
                  labelValue(t('assessmentReport.labels.patientNumber'), patient.id, {
                    width: '100%',
                    display: 'block',
                  })}
                {!hasReasonForEdit && (
                  <>
                    {labelValue(t('assessmentReport.labels.startDateTime'), startedAtDisplay, {
                      width: '100%',
                      display: 'block',
                    })}
                    {labelValue(t('assessmentReport.labels.endDateTime'), completedAtDisplay, {
                      width: '100%',
                      display: 'block',
                    })}
                  </>
                )}
              </Box>
            </>
          )}

          {hasReasonForEdit && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Typography
                sx={{ textAlign: 'center', fontSize: '24px', fontWeight: 500 }}
                component={'h3'}
              >
                {t('assessmentReport.labels.editSummary')}:
              </Typography>
              <Box>
                {labelValue(t('assessmentReport.labels.editStartDateTime'), startedAtDisplay)}
                {labelValue(t('assessmentReport.labels.editEndDateTime'), completedAtDisplay)}
                {labelValue(t('assessmentReport.labels.totalTime'), totalTime)}
                {labelValue(t('assessmentReport.labels.editedBy'), interviewerName)}
                {labelValue(t('assessmentReport.labels.reasonForEdit'), parsedNotes)}
              </Box>
            </Box>
          )}
        </Box>
      )}

      {isMINI && getTableRows() && getTableRows().length !== 0 && (
        <>
          <Box component="div">
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCellHeadStyled></TableCellHeadStyled>
                  <TableCellHeadStyled>
                    {t('assessmentReport.summaryPage.labels.modules')}
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    {t('assessmentReport.summaryPage.labels.timeFrame')}
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    {t('assessmentReport.summaryPage.labels.meetsCriteria')}
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    {t('assessmentReport.summaryPage.labels.icd10Cm')}
                  </TableCellHeadStyled>
                  <TableCellHeadStyled>
                    {t('assessmentReport.summaryPage.labels.primaryDiagnosis')}
                  </TableCellHeadStyled>
                </TableRow>
              </TableHead>
              <TableBody>{getTableRows()}</TableBody>
            </Table>
          </Box>
          <Box component="div">
            <Box
              className="legend"
              component="div"
              sx={{
                margin: '10px 0',
                fontWeight: 'bold',
                backgroundColor: theme.xPalette.lightGreyBackground,
                pageBreakInside: 'avoid',
                textAlign: 'center',
              }}
            >
              {t('assessmentReport.summaryPage.labels.legend', {
                lng: interview?.language,
              })}
            </Box>
            <Box display="flex" component="div">
              <Box
                component="div"
                sx={{ display: 'flex', marginRight: '2rem', pageBreakInside: 'avoid' }}
              >
                <ReportCheckboxUnfilled />
                <Typography>
                  {t('assessmentReport.summaryPage.labels.legendDoesNotMeet', {
                    lng: interview?.language,
                  })}
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{ display: 'flex', marginRight: '2rem', pageBreakInside: 'avoid' }}
              >
                <ReportCheckboxFilled />
                <Typography>
                  {t('assessmentReport.summaryPage.labels.legendMeets', {
                    lng: interview?.language,
                  })}
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{ display: 'flex', marginRight: '2rem', pageBreakInside: 'avoid' }}
              >
                <ReportCheckboxPartialFill />
                <Typography>
                  {t('assessmentReport.summaryPage.labels.legendNa', {
                    lng: interview?.language,
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
