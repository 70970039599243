import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface TableProps {
  headers: { text: string; sx?: SxProps; sortKey?: string }[];
  items: { component: JSX.Element; sx?: SxProps }[][];
  commonCellStyles?: SxProps;
  sort?: { field: string; dir: string };
  onSorted?: (sortKey: string) => void;
}

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.xPalette.lightGrey,
    padding: '8px 24px',
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: theme.xPalette.background,
    borderBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: '14px 24px',
    fontWeight: theme.typography.h4.fontWeight,
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    maxWidth: 100,
    wordBreak: 'break-word',
    color: color,
  },
}));

const StyledSortIconBox = styled(Box)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
}));

export default function DataTable({
  headers,
  items,
  commonCellStyles,
  sort,
  onSorted,
}: TableProps) {
  let headerKey = 0;
  let rowKey = 0;

  const onHeaderClicked = (sortKey?: string) => {
    if (sortKey && onSorted) {
      onSorted(sortKey);
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <TableContainer
        data-testid="dashboard-table"
        elevation={0}
        component={Paper}
        sx={{ borderRadius: '16px 16px 0 0' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((column) => {
                const sortIcon =
                  sort && sort.field === column.sortKey ? (
                    <StyledSortIconBox>
                      {sort.dir === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </StyledSortIconBox>
                  ) : (
                    <></>
                  );
                return (
                  <StyledTableCell
                    key={++headerKey}
                    sx={
                      {
                        ...commonCellStyles,
                        ...column.sx,
                        cursor: column.sortKey ? 'pointer' : 'unset',
                      } as SxProps
                    }
                    onClick={column.sortKey ? () => onHeaderClicked(column.sortKey) : undefined}
                  >
                    {column.text}
                    {sortIcon}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => {
              let cellKey = 0;
              return (
                <TableRow key={++rowKey}>
                  {row.map((column) => {
                    return (
                      <StyledTableCell
                        key={++cellKey}
                        sx={{ ...commonCellStyles, ...column.sx } as SxProps}
                      >
                        {column.component}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
