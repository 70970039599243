import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import isURL from 'validator/lib/isURL';
import * as yup from 'yup';
import { ArrowBack } from '@mui/icons-material';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { buildAuthUrl } from '../../app/services';
import { useForgotPasswordMutation, useLoginMutation } from '../../app/services/auth';
import { useToast } from '../../app/toast';
import AnonymousLayout from '../../common/layouts/Anonymous';
import { newRelic } from '../../common/utils/newRelic';
import { getPageTitle } from '../../common/utils/pageUtils';
import { saveSessionCookie } from '../../common/utils/storageUtils';
import { Account, LoginOrCheckConfigSsoRequest } from '../../types/AuthRequests';
import { ResponseError } from '../../types/ResponseError';
import { ControlledTextField, enhancedStyles } from './ControlledTextField';
import { clearCredentials, setCredentials, selectUser } from './authSlice';

export default function LoginPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();
  const [forgotPassword] = useForgotPasswordMutation();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [isShowMfa, setIsShowMfa] = useState<boolean | undefined>(false);
  const [accounts, setAccounts] = useState<Account[] | undefined>([]);
  const [isShowPassword, setIsShowPassword] = useState<boolean | undefined>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [account, setAccount] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const accessToken = searchParams.get('accessToken') || '';
  const ssoKey = searchParams.get('ssoKey') || '';
  const email = decodeURIComponent(searchParams.get('email') || '');
  const error = searchParams.get('error') || '';
  const currentUser = useAppSelector(selectUser);

  const filterAccountOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => getAccountOptionLabel(option),
  });

  const getAccountOptionLabel = (option: any) => `${option.name} (${option.id})`;

  // Form setup
  const validationSchema = yup
    .object({
      email: yup
        .string()
        .email(t('login.error.invalidEmail'))
        .required(t('login.error.blankEmail')),
      clinicAccount: yup.string().when('isRequiredAccount', {
        is: true,
        then: yup.string().required(t('login.error.blankAccount')),
      }),
      password: yup.string().when('isRequiredPassword', {
        is: true,
        then: yup.string().required(t('login.error.blankPassword')),
      }),
    })
    .required();

  const {
    control,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    handleSubmit,
    reset,
  } = useForm<LoginOrCheckConfigSsoRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (ssoKey) {
      setIsShowPassword(true);
      setValue('email', email);
      setValue('isNoEmailResponse', true);
      setValue('ssoToken', ssoKey);
      setValue('isRequiredPassword', true);
    }
    if (error) {
      setPageErrors([error]);
    }
  }, [ssoKey, setValue, email, error]);

  useEffect(() => {
    if (accessToken) {
      const getUserInfo = async (token: string) => {
        const httpClient = axios.create();
        httpClient.defaults.timeout = 3000;
        const { data: infoUserLogin } = await httpClient.post(
          buildAuthUrl('/v1/auth/check-token-sso'),
          { token },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (infoUserLogin && infoUserLogin.user) {
          dispatch(clearCredentials());
          dispatch(
            setCredentials({
              user: infoUserLogin.user,
              accessToken: infoUserLogin.accessToken,
              refreshToken: infoUserLogin.refreshToken,
              isShowMfa: infoUserLogin.isShowMfa,
              remainingTime: infoUserLogin.remainingTime,
            })
          );

          setPageErrors([]);
          saveSessionCookie();
          navigate('/dashboard', { replace: true });
        }
      };

      if (!Object.values(currentUser).length) {
        getUserInfo(accessToken);
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLogin && !pageErrors.length) {
      reset();
      saveSessionCookie();
      if (isShowMfa) {
        navigate('/mfa', { replace: true });
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
  }, [isLogin, pageErrors, reset, navigate, isShowMfa]);

  // Events
  const handleLogin = async (data: LoginOrCheckConfigSsoRequest) => {
    try {
      dispatch(clearCredentials()); // clear credentials, mfa for patient flow
      const {
        redirectUrl,
        clinicAccounts,
        showPassword,
        user,
        accessToken,
        refreshToken,
        isShowMfa,
        remainingTime,
        urlResetPassword,
        clinicAccountId,
      } = await login(data).unwrap();
      if (redirectUrl) {
        if (isURL(redirectUrl)) {
          if (
            isURL(redirectUrl, {
              protocols: ['http', 'https', 'ftp'],
              require_protocol: true,
              require_valid_protocol: true,
            })
          ) {
            window.open(redirectUrl, '_self');
          } else {
            window.open('https://' + redirectUrl, '_self');
          }
        } else {
          setPageErrors([t('login.error.ssoClientConfigInvalid')]);
        }
      } else {
        if (clinicAccounts) {
          setAccounts(
            clinicAccounts.slice().sort((a: Account, b: Account) => (a.name > b.name ? 1 : -1))
          );
          setValue('isRequiredAccount', true);
          setPageErrors([]);
        } else if (showPassword && clinicAccountId) {
          setIsShowPassword(showPassword);
          setValue('clinicAccount', clinicAccountId);
          setValue('isRequiredPassword', true);
          setPageErrors([]);
        } else {
          if (urlResetPassword) {
            navigate(urlResetPassword, { replace: true });
          }
          dispatch(setCredentials({ user, accessToken, refreshToken, isShowMfa, remainingTime }));
          setIsShowMfa(isShowMfa);
          setIsLogin(true);
          setPageErrors([]);
          // monitor logged in user
          newRelic.addPageAction('userLoggedIn', { nViewUserId: user.id });
        }
      }
    } catch (e) {
      const responseError = e as ResponseError;
      if (responseError.data?.error) {
        setPageErrors(responseError.data.error);
      } else {
        setPageErrors([t('error.unexpectedError')]);
      }
    }
  };

  const onSubmit = async (data: LoginOrCheckConfigSsoRequest) => {
    await handleLogin(data);
  };

  const handleBackClick = () => {
    setPageErrors([]);
    if (ssoKey || error) {
      searchParams.delete('ssoKey');
      searchParams.delete('email');
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
    if (isShowPassword) {
      setIsShowPassword(false);
      resetField('password');
      resetField('isRequiredPassword');
      resetField('clinicAccount');
    } else if (accounts && accounts.length > 0) {
      setAccounts([]);
      resetField('clinicAccount');
      resetField('isRequiredAccount');
    }
  };

  const handleAccountChange = (e: any, values: any) => {
    const selectedValue = values?.id ?? '';
    setValue('clinicAccount', selectedValue);
    const account = `Account: ${values?.name}(${values?.id})`;
    setAccount(account);
  };

  const handleForgotPasswordClick = async () => {
    try {
      if (getValues('clinicAccount')) {
        const { message } = await forgotPassword({
          clinicAccount: getValues('clinicAccount'),
          email: getValues('email'),
        }).unwrap();
        toast.publish(message, 'success');
      }
    } catch (error) {
      const responseError = error as ResponseError;
      if (responseError.data?.error) {
        setPageErrors(
          typeof responseError.data.error === 'string'
            ? [responseError.data.error]
            : responseError.data.error
        );
      } else {
        setPageErrors([t('error.unexpectedError')]);
      }
    }
  };

  return (
    <>
      {!accessToken && (
        <AnonymousLayout>
          <Helmet>
            <title>{getPageTitle(t('login.title'))} </title>
          </Helmet>

          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography variant="h1">{t('login.title')}</Typography>

              <Box
                component="form"
                noValidate
                sx={{ mt: 1, width: '100%' }}
                onSubmit={handleSubmit(onSubmit)}
              >
                {ssoKey && (
                  <Typography variant="subtitle2" sx={{ mt: 1, textAlign: 'center' }}>
                    {t('login.requirePasswordFirstTimeSSO')}
                  </Typography>
                )}

                {(accounts && accounts.length > 1) || isShowPassword ? (
                  <Box sx={enhancedStyles.labelWrapper}>
                    <IconButton aria-label="arrow-back" onClick={handleBackClick}>
                      <ArrowBack />
                    </IconButton>
                    <FormLabel
                      sx={{
                        ml: 1,
                        ...enhancedStyles.label,
                      }}
                    >
                      {getValues('email')}
                    </FormLabel>
                  </Box>
                ) : (
                  <ControlledTextField
                    name="email"
                    control={control}
                    fieldError={errors.email}
                    label={t('login.email')}
                    icon={<Email />}
                  />
                )}
                {accounts &&
                  accounts.length > 1 &&
                  (!isShowPassword ? (
                    <Box sx={enhancedStyles.wrapper}>
                      <Box sx={enhancedStyles.icon}>
                        <PeopleAlt />
                      </Box>
                      <Controller
                        name="clinicAccount"
                        control={control}
                        render={({ fieldState, formState, field: { value }, ...props }) => (
                          <Autocomplete
                            {...props}
                            sx={enhancedStyles.dropdown}
                            disabled={isShowPassword}
                            data-testid="mui-component-select-clinic-account"
                            options={accounts ?? []}
                            getOptionLabel={getAccountOptionLabel}
                            onChange={handleAccountChange}
                            value={
                              value ? accounts?.find((item) => item.id === value) || null : null
                            }
                            filterOptions={filterAccountOptions}
                            renderOption={(props, option: any) => {
                              return (
                                <li {...props} key={option.id} value={option.id}>
                                  {getAccountOptionLabel(option)}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors.clinicAccount}
                                helperText={errors.clinicAccount?.message}
                                margin="normal"
                                fullWidth
                                label={t('login.account')}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                  ) : (
                    <Box sx={enhancedStyles.labelWrapper}>
                      <FormLabel sx={enhancedStyles.label}>{account}</FormLabel>
                    </Box>
                  ))}

                {isShowPassword && (
                  <Box sx={{ mt: 1 }}>
                    <ControlledTextField
                      name="password"
                      control={control}
                      fieldError={errors.password}
                      label={t('login.password')}
                      forPassword={true}
                      icon={<Lock />}
                    />
                  </Box>
                )}

                <Box textAlign="center" sx={{ mt: 1 }}>
                  {pageErrors.length > 0 &&
                    pageErrors.map((error) => (
                      <Alert key={error} severity="error" sx={{ mt: 2, textAlign: 'left' }}>
                        {error}
                      </Alert>
                    ))}
                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    {isShowPassword ? t('login.submit') : t('login.next')}
                  </Button>
                  {isShowPassword && !ssoKey && (
                    <Link href="#" onClick={handleForgotPasswordClick}>
                      {t('forgotPassword.title')}
                    </Link>
                  )}
                </Box>
              </Box>
            </Box>
          </Container>
        </AnonymousLayout>
      )}
    </>
  );
}
