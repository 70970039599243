import { buildAuthUrl, QueryCacheDuration, rootApi } from '.';
import { filterRequest } from '../../common/utils/commonUtils';
import {
  CheckExpireUrlResponse,
  EhrLoginRequest,
  EhrLoginResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginOrCheckConfigSsoRequest,
  loginResponse,
  MfaCodeRequest,
  MfaCodeResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UserResponse,
  VerifyMfaRequest,
} from '../../types/AuthRequests';
import { CheckExpireTokenRequest } from '../../types/InterviewRequest';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<loginResponse, LoginOrCheckConfigSsoRequest>({
      query: (credentials) => ({
        url:
          credentials.password && credentials.isNoEmailResponse
            ? buildAuthUrl('/v1/auth/verify-sso-login')
            : credentials.password && credentials.password !== ''
            ? buildAuthUrl('/v1/auth/login')
            : buildAuthUrl(`/v1/auth/check-config-sso`),
        method: 'POST',
        body:
          credentials.password && credentials.isNoEmailResponse
            ? filterRequest(credentials, ['ssoToken', 'password'])
            : credentials.password && credentials.password !== ''
            ? filterRequest(credentials, ['email', 'clinicAccount', 'password'])
            : filterRequest(credentials, ['email', 'clinicAccount']),
      }),
    }),
    ehrCheckLogin: builder.query<EhrLoginResponse, EhrLoginRequest>({
      query: (credentials) => ({
        url: buildAuthUrl('/v1/auth/ehr-check-login'),
        method: 'GET',
        params: {
          ehrType: credentials.ehrType,
          launch: credentials.launch,
          iss: credentials.iss,
        },
      }),
      keepUnusedDataFor: QueryCacheDuration.NoCache,
    }),
    logout: builder.mutation({
      query: (_: void) => ({
        url: buildAuthUrl('/v1/auth/logout'),
        method: 'POST',
        body: {},
      }),
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: buildAuthUrl('/v1/auth/forgot-password'),
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
      query: (credentials) => ({
        url: buildAuthUrl('/v1/auth/reset-password'),
        method: 'POST',
        body: credentials,
      }),
    }),
    checkExpireUrlResetPassword: builder.query<CheckExpireUrlResponse, CheckExpireTokenRequest>({
      query: (request) => ({
        url: buildAuthUrl('/v1/auth/check-expire-url-reset-password'),
        method: 'GET',
        params: { token: request.token, hash: request.tokenHash },
      }),
    }),
    getMfaCode: builder.mutation<MfaCodeResponse, MfaCodeRequest>({
      query: (request) => ({
        url: !request.patientId
          ? buildAuthUrl('/v1/auth/get-mfa-code')
          : buildAuthUrl('/v1/auth/get-mfa-code-patient'),
        method: 'POST',
        body: request,
      }),
    }),
    resendMfaCode: builder.mutation<MfaCodeResponse, MfaCodeRequest>({
      query: (request) => ({
        url: !request.patientId
          ? buildAuthUrl('/v1/auth/resend-mfa-code')
          : buildAuthUrl('/v1/auth/resend-mfa-code-patient'),
        method: 'POST',
        body: request,
      }),
    }),
    verifyMfaCode: builder.mutation<UserResponse, VerifyMfaRequest>({
      query: (request) => ({
        url: !request.patientId
          ? buildAuthUrl('/v1/auth/verify-mfa-code')
          : buildAuthUrl('/v1/auth/verify-mfa-code-patient'),
        method: 'POST',
        body: request,
      }),
    }),
    logoutMfa: builder.mutation({
      query: (request) => ({
        url: buildAuthUrl('/v1/auth/logout-mfa'),
        method: 'POST',
        body: { ...request },
      }),
    }),

    resetPatientMfa: builder.mutation<any, { patientId: string }>({
      query: (body) => ({
        url: buildAuthUrl('/v1/auth/reset-mfa-patient'),
        method: 'POST',
        body,
      }),
    }),

    resetUserPassword: builder.mutation<{ message: string }, { userId: number }>({
      query: (body) => ({
        url: buildAuthUrl('/v1/auth/reset-user-password'),
        method: 'POST',
        body,
      }),
    }),
    resetUserMfa: builder.mutation<void, { userId: number }>({
      query: (body) => ({
        url: buildAuthUrl('/v1/auth/reset-mfa-user'),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyMfaCodeMutation,
  useCheckExpireUrlResetPasswordQuery,
  useGetMfaCodeMutation,
  useResendMfaCodeMutation,
  useLogoutMfaMutation,
  useResetPatientMfaMutation,
  useResetUserPasswordMutation,
  useResetUserMfaMutation,
  useEhrCheckLoginQuery,
} = api;

export const { endpoints: authApis } = api;
