import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '@mui/material/Container';
import { useGetClinicAccountsQuery, useGetClinicSitesQuery } from '../../app/services/account';
import { useResetPatientMfaMutation } from '../../app/services/auth';
import {
  useAddPatientMutation,
  useConductInterviewPatientAutoIntakePacketMutation,
  useEditPatientMutation,
  useGetPatientQuery,
} from '../../app/services/patient';
import { useToast } from '../../app/toast';
import PageHeader from '../../common/components/PageHeader';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { PageSource } from '../../common/utils/pageInfo';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { PatientInfo } from '../../types/PatientRequest';
import ErrorPage from '../error/ErrorPage';
import PatientForm from './PatientForm';
import { clearAssessmentCachedData } from './patientUtils';

export default function AddOrEditPatientPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const patientId: string | undefined = params.patientId;
  const [searchParams] = useSearchParams();
  const [isSendIntakePacket, setIsSendIntakePacket] = useState<boolean>(false);

  const [addPatient] = useAddPatientMutation();
  const [editPatient] = useEditPatientMutation();
  const [conductInterviewPatientAutoIntakePacket] =
    useConductInterviewPatientAutoIntakePacketMutation();
  const { data, error } = useGetPatientQuery(patientId ? { patientId } : skipToken);
  const { data: clinicAccountData } = useGetClinicAccountsQuery();
  const { data: clinicSiteData } = useGetClinicSitesQuery();
  const [resetPatientMfa] = useResetPatientMfaMutation();
  const { state } = useLocation();
  const { cancelUrl } = !!state && state;
  const fromPatientList = searchParams.get('src');
  const newBreadcrumb: BreadcrumbType =
    fromPatientList === PageSource.PatientList
      ? {
          text: t('patient.patientList.title'),
          link: cancelUrl || `/dashboard/patients`,
        }
      : fromPatientList === PageSource.PatientDashboard
      ? {
          text: t('patient.patientDashboard.title'),
          link: `/dashboard/patient/${patientId}`,
        }
      : {
          text: t('dashboard.title'),
          link: `/dashboard`,
        };

  const onResetMfa = async () => {
    await resetPatientMfa({ patientId: patientId! });
    toast.publish(t('mfa.forms.resetSuccess'), 'success', 3000);
  };

  const onFormSubmit = async (
    data: PatientInfo,
    patientId?: string,
    successCallback?: () => void
  ) => {
    let createdPatientId;
    if (data && !data.providerId) {
      data.providerId = undefined;
    }
    if ((data && !data.email && !data.cellPhoneNumber) || !isSendIntakePacket) {
      delete data.providerId;
    }
    if (patientId) {
      await editPatient({ data, patientId }).unwrap();
    } else {
      const createdData = await addPatient(data).unwrap();
      createdPatientId = createdData?.patientId;
    }
    //Call API conduct Interview if have config Auto Intake packet
    const patientIdFinal = patientId ? patientId : createdPatientId ? createdPatientId : '';
    if (data && data.providerId && isSendIntakePacket) {
      await conductInterviewPatientAutoIntakePacket({ patientId: patientIdFinal }).unwrap();
      clearAssessmentCachedData();
    }

    if (successCallback) {
      successCallback();
    }

    // back to dashboard in Add Patient case
    if (createdPatientId) {
      navigate(`/dashboard/patient/${createdPatientId}`);
    }

    toast.publish(
      (patientId && t('patient.editPatient.updateSuccess')) || t('patient.addPatient.addSuccess'),
      'success'
    );
  };

  if (error) {
    console.error(error);
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  const changeIsSendIntakePacket = (value: boolean): void => {
    setIsSendIntakePacket(value);
  };

  return (
    <DashboardLayout breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}>
      <Helmet>
        <title>
          {patientId
            ? getPageTitle(t('patient.editPatient.title'))
            : getPageTitle(t('patient.addPatient.title'))}
        </title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader
          sx={{ mt: 1, mb: 2 }}
          headerText={patientId ? t('patient.editPatient.title') : t('patient.addPatient.title')}
          backUrl={
            fromPatientList === PageSource.PatientList
              ? `/dashboard/patients`
              : fromPatientList === PageSource.PatientDashboard
              ? `/dashboard/patient/${patientId}`
              : `/dashboard`
          }
        />
      </Container>

      {patientId && (
        <RoundedContainer sx={{ py: 2, mb: 2 }}>
          <PatientInfoCard
            data={data!}
            clinicAccounts={clinicAccountData?.clinicAccounts}
            patientIdOnly={true}
          />
        </RoundedContainer>
      )}

      <RoundedContainer>
        <PatientForm
          patientId={patientId}
          data={data}
          clinicAccounts={clinicAccountData?.clinicAccounts}
          clinicSites={clinicSiteData?.clinicSites}
          onFormSubmit={onFormSubmit}
          onResetMfa={onResetMfa}
          isSendIntakePacket={isSendIntakePacket}
          setIsSendIntakePacket={changeIsSendIntakePacket}
        />
      </RoundedContainer>
    </DashboardLayout>
  );
}
