import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ContextMenuItem {
  isDisplayed?: boolean;
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  isGroupHeader?: boolean;
}

interface Props {
  id: string;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  menuItems: ContextMenuItem[];
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 40,
      horizontal: -152,
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    maxWidth: 240 ,
    borderRadius: 10,
  },
  '& .MuiMenuItem-root': {
    whiteSpace: 'unset',
    lineBreak: 'strict',
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledMenuHeader = styled(MenuItem)(() => ({
  textTransform: 'uppercase',

  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'unset',
  },
}));

export default function ContextMenu({ id, anchorEl, menuItems, handleClick, handleClose }: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const anchorElId = `${id}-button`;
  const isOpen = (anchorEl && anchorEl.id === anchorElId) || false;

  return (
    <>
      <IconButton
        id={anchorElId}
        aria-label={`${id}-table-menu`}
        aria-controls={`${id}-menu`}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        sx={{ p: 0 }}
      >
        <Box
          component="span"
          sx={{
            p: matches ? 0 : 0.4,
            borderRadius: '50%',
            border: 1.4,
            lineHeight: 0,
            color: theme.palette.primary.main,
          }}
        >
          <MoreHorizOutlinedIcon />
        </Box>
      </IconButton>
      <StyledMenu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
        }}
      >
        {menuItems?.length &&
          menuItems
            .filter((item) => item.isDisplayed === undefined || item.isDisplayed) // always show if isDisplayed is not set
            .map((item) =>
              item.isGroupHeader ? (
                <StyledMenuHeader key={item.label} disableRipple>
                  <Typography variant="subtitle1">{item.label}</Typography>
                </StyledMenuHeader>
              ) : (
                <MenuItem onClick={item.onClick} key={item.label} disabled={item.disabled}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              )
            )}
      </StyledMenu>
    </>
  );
}
