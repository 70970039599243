import { JSXElementConstructor } from 'react';
import { generatePath } from 'react-router-dom';
import LoadingPage from '../common/components/LoadingPage';
import ResumeAssessment from '../common/components/ResumeAssessment';
import { InterviewMode } from '../common/utils/interviewsMode';
import { MonitorModeName } from '../common/utils/monitorsMode';
import { staticPageUrls } from '../common/utils/staticPagePath';
// import { PUBLIC_HTML_FOLDER, staticPageFiles } from '../common/utils/staticPagePath';
import AccountListPage from '../features/account/AccountListPage';
import AddOrEditAccountPage from '../features/account/AddOrEditAccountPage';
import BrandSettings from '../features/account/BrandSettings';
import ClinicalFollowUpsEmailSignaturePage from '../features/account/clinicalFollowUps/ClinicalFollowUpsEmailSignaturePage';
import ClinicalFollowUpsPage from '../features/account/clinicalFollowUps/ClinicalFollowUpsPage';
import ClinicalFollowUpsSignatureContenrPage from '../features/account/clinicalFollowUps/ClinicalFollowUpsSignatureContentPage';
import AccountMessagingPage from '../features/accountMessaging/AccountMessagingPage';
import AddOrEditAssessmentType from '../features/assessmentBuilder/AddOrEditAssessmentType';
import AssessmentBuilderBuilderPage from '../features/assessmentBuilder/AssessmentBuilderBuilderPage';
import AssessmentTypeListPage from '../features/assessmentBuilder/AssessmentTypeListPage';
import MainReportPage from '../features/assessmentReport/MainReportPage';
import AuditLogFilterPage from '../features/auditLog/AuditLogFilterPage';
import AuditLogResultPage from '../features/auditLog/AuditLogResultPage';
import EhrLoginPage from '../features/auth/EhrLoginPage';
import ForgotPasswordPage from '../features/auth/ForgotPasswordPage';
import LoginPage from '../features/auth/LoginPage';
import MfaPage from '../features/auth/MfaPage';
import ResetPasswordPage from '../features/auth/ResetPasswordPage';
import CacheManagementPage from '../features/cache-management/CacheManagementPage';
import ClinicalFollowUpPage from '../features/clinicalFollowup/ClinicalFollowupPage';
import DashboardPage from '../features/dashboard/DashboardPage';
import ErrorPage from '../features/error/ErrorPage';
import RawDataExportPage from '../features/export/RawDataExportPage';
import StructuredDataCodebook from '../features/export/StructuredDataCodebook';
import StructuredDataCodebookDetail from '../features/export/StructuredDataCodebookDetail';
import StructuredDataExportPage from '../features/export/StructuredDataExportPage';
import StructuredScoredDataCodebook from '../features/export/StructuredScoredDataCodebook';
import StructuredScoredDataCodebookDetail from '../features/export/StructuredScoredDataCodebookDetail';
import ExternalRedirectPage from '../features/external/ExternalRedirectPage';
import ImportInterviewsPage from '../features/importInterviews/ImportInterviewsPage';
import CompleteInterviewPage from '../features/interview/CompleteInterviewPage';
import ConductInterviewPage from '../features/interview/ConductInterviewPage';
import ContinueNextInterviewPage from '../features/interview/ContinueNextInterviewPage';
import EditInterviewPage from '../features/interview/EditInterviewPage';
import PatientStartInterviewPage from '../features/interview/PatientStartInterviewPage';
import StartInterviewPage from '../features/interview/StartInterviewPage';
import { InterviewReportType } from '../features/interview/interviewUtils';
import ManagementChartReportForDownloadPage from '../features/managementChartReport/ManagementChartReportForDownloadPage';
import ManagementChartReportPage from '../features/managementChartReport/ManagementChartReportPage';
import ManagementReportContentPage from '../features/managementChartReport/ManagementReportContentPage';
import MasterUsageReportFilterPage from '../features/master-usage-report/MasterUsageReportFilterPage';
import CompleteMonitorPage from '../features/monitor/CompleteMonitorPage';
import ConductMonitorPage from '../features/monitor/ConductMonitorPage';
import ContinueNextMonitorPage from '../features/monitor/ContinueNextMonitorPage';
import PatientStartMonitorPage from '../features/monitor/PatientStartMonitorPage';
import StartMonitorPage from '../features/monitor/StartMonitorPage';
import AddOrEditPatientPage from '../features/patient/AddOrEditPatientPage';
import PatientDashboard from '../features/patient/PatientDashboard';
import PatientListPage from '../features/patient/PatientListPage';
import ProviderFeedbackPage from '../features/patient/ProviderFeedbackPage';
import SuicideAlertPage from '../features/patient/SuicideAlertPage';
import ImportPatientPage from '../features/patient/importPatient/ImportPatientPage';
import PatientNotesPage from '../features/patient/patientNotes/PatientNotesPage';
import PatientChartReportForDownloadPage from '../features/patientChartReport/PatientChartReportForDownloadPage';
import PatientChartReportPage from '../features/patientChartReport/PatientChartReportPage';
import PatientReportContentPage from '../features/patientChartReport/PatientReportContentPage';
import EditProfile from '../features/profile/EditProfile';
import ReportHistoryPage from '../features/report/ReportHistoryPage';
import UsageReportFilterPage from '../features/report/UsageReportFilterPage';
import UsageReportResultPage from '../features/report/UsageReportResultPage';
import AddJobPage from '../features/rescoreJobs/AddJobPage';
import RescoreJobListPage from '../features/rescoreJobs/RescoreJobListPage';
import SchedulePage from '../features/schedule/SchedulePage';
import SharpenContentPage from '../features/sharpen/SharpenContentPage';
import AddOrEditSitePage from '../features/site/AddOrEditSitePage';
import SiteListPage from '../features/site/SiteListPage';
import AddOrEditSsoClientPage from '../features/ssoClient/AddOrEditSsoClientPage';
import SsoClientListPage from '../features/ssoClient/SsoClientListPage';
import DocumentPage from '../features/support/DocumentPage';
import SupportPage from '../features/support/SupportPage';
import AddOrEditTemplatePage from '../features/template/AddOrEditTemplatePage';
import TemplateListPage from '../features/template/TemplateListPage';
import AddOrEditTreatmentPlanPage from '../features/treatment-plan/AddOrEditTreatmentPlanPage';
import TreatmentPlanListPage from '../features/treatment-plan/TreatmentPlanListPage';
import AddOrEditGoalPage from '../features/treatment-plan/goal/AddOrEditGoalPage';
import TreatmentPlanReportPage from '../features/treatment-plan/report/TreatmentPlanReportPage';
import ViewTreatmentPlanReport from '../features/treatment-plan/report/ViewTreatmentPlanReport';
import EmailSignaturePage from '../features/treatment-plan/signature/EmailSignaturePage';
import UnlockUserPage from '../features/unlockUser/unlockUserPage';
import AddOrEditUserPage from '../features/user/AddOrEditUserPage';
import ImportUserPage from '../features/user/ImportUserPage';
import UserListPage from '../features/user/UserListPage';
import { PageProps, SharpenPageProps } from '../types/PageProps';
import { TranslateFunction } from '../types/TranslateFunction';

export interface RouteConfigItem {
  path: string;
  component?: JSXElementConstructor<PageProps>;
  requireAuth?: boolean;
  redirectTo?: string;
  breadcrumbName?: string;
  disableAutoBreadcrumb?: boolean;
  showDashboardBreadcrumb?: boolean;
}

export interface SharpenConfigItem {
  path: string;
  component?: JSXElementConstructor<SharpenPageProps>;
  src?: string;
  title?: string;
}

export const buildRedirectUrl = (url?: string) => `/_redirect?url=${encodeURIComponent(url!)}`;

const routeReportType = {
  question: 'question',
  screenerPage: 'screenerPage',
  ybocsPage: 'ybocsPage',
};

export const routeReportTypeMap = {
  [InterviewReportType.MINI]: routeReportType.question,
  [InterviewReportType.Screener]: routeReportType.screenerPage,
  [InterviewReportType.SdsSts]: routeReportType.screenerPage,
  [InterviewReportType.YBOCS]: routeReportType.ybocsPage,
  [InterviewReportType.CYBOCS]: routeReportType.ybocsPage,
};

export const buildInterviewConductUrl = (
  reportType: string,
  interviewMode: string,
  context: { interviewResultId: string; patientId?: string },
  editInterview?: boolean
) => {
  let path =
    interviewMode === InterviewMode.Clinician
      ? '/dashboard/current-interview/:reportType/:patientId/:interviewResultId'
      : '/current-interview/:reportType/:interviewResultId';

  if (editInterview) {
    path = '/dashboard/current-interview/:reportType/:patientId/:interviewResultId/edit-interview';
  }

  return generatePath(path, {
    reportType: routeReportTypeMap[reportType],
    patientId: context.patientId,
    interviewResultId: context.interviewResultId,
  });
};

export const buildMonitorConductUrl = (
  monitorMode: string,
  context: { monitorResultId: string; patientId?: string }
) => {
  const path =
    monitorMode === MonitorModeName.Clinician
      ? '/dashboard/current-monitor/screenerPage/:patientId/:monitorResultId'
      : '/current-monitor/screenerPage/:monitorResultId';

  return generatePath(path, {
    patientId: context.patientId,
    monitorResultId: context.monitorResultId,
  });
};

export const isValidInterviewConductRoute = (reportTypePath: string) =>
  [routeReportType.question, routeReportType.screenerPage, routeReportType.ybocsPage].includes(
    reportTypePath
  );

export const getSharpenRoutes: (t: TranslateFunction) => SharpenConfigItem[] = (t) => {
  // return staticPageFiles.map((file: string) => {
  //   const fileName = file.split('.')[0];

  //   return {
  //     path: `/sharpen-content/${fileName}`,
  //     component: SharpenContentPage,
  //     src: `${PUBLIC_HTML_FOLDER}${file}`,
  //     title: fileName,
  //   };
  // });

  return Object.keys(staticPageUrls).map((urlName: string) => ({
    path: `/sharpen-content/${urlName}`,
    component: SharpenContentPage,
    src: staticPageUrls[urlName],
    title: urlName,
  }));
};

export const getRoutes: (t: TranslateFunction) => RouteConfigItem[] = (t) => [
  {
    path: '/assessment/expire',
    component: ResumeAssessment,
  },
  {
    path: '/loading',
    component: LoadingPage,
    requireAuth: true,
  },
  {
    path: '/dashboard/treatment-plan-report/:patientId/:treatmentPlanId',
    component: ViewTreatmentPlanReport,
    requireAuth: true,
  },
  { path: '/signature/:token/:tokenHash', component: EmailSignaturePage },
  {
    path: '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types/edit/:assessmentTypeId',
    component: AddOrEditAssessmentType,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.monitorType.edit'),
  },
  {
    path: '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types/add',
    component: AddOrEditAssessmentType,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.monitorType.add'),
  },
  {
    path: '/dashboard/assessment-builder/interview/:assessmentId/interview-types/edit/:assessmentTypeId',
    component: AddOrEditAssessmentType,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.interviewType.edit'),
  },
  {
    path: '/dashboard/assessment-builder/interview/:assessmentId/interview-types/add',
    component: AddOrEditAssessmentType,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.interviewType.add'),
  },
  {
    path: '/dashboard/assessment-builder',
    component: AssessmentBuilderBuilderPage,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.title'),
  },
  {
    path: '/dashboard/assessment-builder/interview/:assessmentId/interview-types',
    component: AssessmentTypeListPage,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.interviewType.title'),
  },
  {
    path: '/dashboard/assessment-builder/monitor/:assessmentId/monitor-types',
    component: AssessmentTypeListPage,
    requireAuth: true,
    breadcrumbName: t('assessmentBuilder.assessmentType.monitorType.title'),
  },
  {
    path: '/signature/:token/:tokenHash',
    component: EmailSignaturePage,
  },
  {
    path: '/clinical-followup-signature/:token/:tokenHash',
    component: ClinicalFollowUpsEmailSignaturePage,
  },
  {
    path: '/dashboard/templates',
    component: TemplateListPage,
    requireAuth: true,
    breadcrumbName: t('eSignature.template.title'),
  },
  {
    path: '/dashboard/patient/:patientId/notes',
    component: PatientNotesPage,
    requireAuth: true,
    breadcrumbName: t('patient.patientDashboard.form.patientNote'),
  },
  {
    path: '/dashboard/account-messaging',
    component: AccountMessagingPage,
    requireAuth: true,
    breadcrumbName: t('accountMessaging.title'),
  },
  {
    path: '/dashboard/accounts/clinical-follow-ups/:clinicalAccountId',
    component: ClinicalFollowUpsPage,
    requireAuth: true,
    breadcrumbName: t('account.manageAccounts.accountForm.clinicalFollowUps.title'),
  },
  {
    path: '/dashboard/account-messaging',
    component: AccountMessagingPage,
    requireAuth: true,
    breadcrumbName: t('accountMessaging.title'),
  },
  {
    path: '/dashboard/rescore-jobs',
    component: RescoreJobListPage,
    requireAuth: true,
    breadcrumbName: t('interviews.rescoreJobs.title'),
  },
  {
    path: '/dashboard/rescore-jobs/add-jobs',
    component: AddJobPage,
    requireAuth: true,
    breadcrumbName: t('interviews.rescoreJobs.addJobs.title'),
  },
  {
    path: '/dashboard/structured-export',
    component: StructuredDataExportPage,
    requireAuth: true,
    breadcrumbName: t('exportData.structured.title'),
  },
  {
    path: '/dashboard/structured-export/structured-data-codebook',
    component: StructuredDataCodebook,
    requireAuth: true,
    breadcrumbName: t('exportData.structured.codebook.title'),
  },
  {
    path: '/dashboard/structured-export/structured-data-codebook/detail/:interviewTypeId',
    component: StructuredDataCodebookDetail,
    requireAuth: true,
    breadcrumbName: t('exportData.structured.codebook.detail.title'),
  },
  {
    path: '/dashboard/structured-export/structured-scored-data-codebook',
    component: StructuredScoredDataCodebook,
    requireAuth: true,
    breadcrumbName: t('exportData.structured.scored.codebook.title'),
  },
  {
    path: '/dashboard/structured-export/structured-scored-data-codebook/detail/:interviewTypeId',
    component: StructuredScoredDataCodebookDetail,
    requireAuth: true,
    breadcrumbName: t('exportData.structured.scored.codebook.detail.title'),
  },
  {
    path: '/dashboard/rescore-jobs',
    component: RescoreJobListPage,
    requireAuth: true,
    breadcrumbName: t('interviews.rescoreJobs.title'),
  },
  {
    path: '/dashboard/rescore-jobs/add-jobs',
    component: AddJobPage,
    requireAuth: true,
    breadcrumbName: t('interviews.rescoreJobs.addJobs.title'),
  },
  {
    path: '/dashboard/patient/:patientId/interview-results/:interviewResultId',
    component: MainReportPage,
    breadcrumbName: t('assessmentReport.title'),
  },
  {
    path: '/dashboard/patient/:patientId/monitor-results/:monitorResultId',
    component: MainReportPage,
    breadcrumbName: t('assessmentReport.title'),
  },
  {
    path: '/dashboard/management-report',
    component: ManagementChartReportPage,
    requireAuth: true,
    breadcrumbName: t('managementReport.title'),
  },
  {
    path: '/dashboard/patient/:patientId/patient-report',
    component: PatientChartReportPage,
    requireAuth: true,
    breadcrumbName: t('patientReport.title'),
  },
  {
    path: '/report/management/download',
    component: ManagementChartReportForDownloadPage,
    breadcrumbName: t('managementReport.title'),
  },
  {
    path: '/report/management/:reportNameActive/:reportName',
    component: ManagementReportContentPage,
    requireAuth: true,
    breadcrumbName: t('managementReport.title'),
  },
  {
    path: '/report/patient/:patientId/download',
    component: PatientChartReportForDownloadPage,
    breadcrumbName: t('patientReport.title'),
  },
  {
    path: '/report/patient/:patientId/:reportName',
    component: PatientReportContentPage,
    requireAuth: true,
    breadcrumbName: t('patientReport.title'),
  },
  {
    path: '/dashboard/patients/import-patient',
    component: ImportPatientPage,
    requireAuth: true,
    breadcrumbName: t('patient.importPatient.title'),
  },
  {
    path: '/dashboard/raw-export',
    component: RawDataExportPage,
    requireAuth: true,
    breadcrumbName: t('exportData.raw.title'),
  },
  {
    path: '/dashboard/import-interviews',
    component: ImportInterviewsPage,
    requireAuth: true,
    breadcrumbName: t('interviews.importInterviews.title'),
  },
  {
    path: '/dashboard/patient/:patientId/diagnosis',
    component: ProviderFeedbackPage,
    requireAuth: true,
    breadcrumbName: t('patient.providerFeedback.title'),
  },
  {
    path: '/dashboard/accounts/edit-brand/:accountId',
    component: BrandSettings,
    requireAuth: true,
    breadcrumbName: t('account.manageAccounts.brandSettings.title'),
  },
  {
    path: '/dashboard/accounts/edit-account/:accountId',
    component: AddOrEditAccountPage,
    requireAuth: true,
    breadcrumbName: t('account.manageAccounts.editAccount'),
  },
  {
    path: '/dashboard/accounts/add-account',
    component: AddOrEditAccountPage,
    requireAuth: true,
    breadcrumbName: t('account.manageAccounts.addAccount'),
  },
  {
    path: '/dashboard/accounts',
    component: AccountListPage,
    requireAuth: true,
    breadcrumbName: t('account.manageAccounts.title'),
  },
  {
    path: '/dashboard/users/edit-user/:userId',
    component: AddOrEditUserPage,
    requireAuth: true,
    breadcrumbName: t('user.manageUsers.editUser'),
  },
  {
    path: '/dashboard/users/add-user',
    component: AddOrEditUserPage,
    requireAuth: true,
    breadcrumbName: t('user.manageUsers.addUser'),
  },
  {
    path: '/dashboard/users/import-user',
    component: ImportUserPage,
    requireAuth: true,
    breadcrumbName: t('user.manageUsers.importUser'),
  },
  {
    path: '/dashboard/users',
    component: UserListPage,
    requireAuth: true,
    breadcrumbName: t('user.manageUsers.title'),
  },
  {
    path: '/dashboard/sites',
    component: SiteListPage,
    requireAuth: true,
    breadcrumbName: t('clinicSite.manageSites.title'),
  },
  {
    path: '/dashboard/sites/edit-site/:siteId',
    component: AddOrEditSitePage,
    requireAuth: true,
    breadcrumbName: t('clinicSite.manageSites.editSite'),
  },
  {
    path: '/dashboard/sites/add-site',
    component: AddOrEditSitePage,
    requireAuth: true,
    breadcrumbName: t('clinicSite.manageSites.addSite'),
  },
  {
    path: '/dashboard/ssoClients',
    component: SsoClientListPage,
    requireAuth: true,
    breadcrumbName: t('ssoClient.manageSsoClient.title'),
  },
  {
    path: '/dashboard/ssoClients/edit-sso-client/:identityProviderId',
    component: AddOrEditSsoClientPage,
    requireAuth: true,
    breadcrumbName: t('ssoClient.manageSsoClient.editSsoClient'),
  },
  {
    path: '/dashboard/ssoClients/add-sso-client',
    component: AddOrEditSsoClientPage,
    requireAuth: true,
    breadcrumbName: t('ssoClient.manageSsoClient.addSsoClient'),
  },
  {
    path: '/dashboard/suicide-alerts',
    component: SuicideAlertPage,
    requireAuth: true,
    breadcrumbName: t('patient.suicidalPatients.title'),
  },
  {
    path: '/dashboard/patient/:patientId/:interviewResultId/edit-interview',
    component: EditInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.editInterview.title'),
  },
  {
    path: '/dashboard/support/video',
    component: DocumentPage,
    requireAuth: true,
    breadcrumbName: t('support.training.videos'),
  },
  {
    path: '/dashboard/support',
    component: SupportPage,
    requireAuth: true,
    breadcrumbName: t('support.title'),
  },
  {
    path: '/dashboard/unlock',
    component: UnlockUserPage,
    requireAuth: true,
    breadcrumbName: t('unlock.title'),
  },
  {
    path: '/dashboard/report',
    component: UsageReportFilterPage,
    requireAuth: true,
    breadcrumbName: t('report.title'),
  },
  {
    path: '/dashboard/master-usage-report',
    component: MasterUsageReportFilterPage,
    requireAuth: true,
    breadcrumbName: t('masterUsageReport.title'),
  },
  {
    path: '/dashboard/report-result',
    component: UsageReportResultPage,
    requireAuth: true,
    breadcrumbName: t('report.usageReport.output'),
  },
  {
    path: '/dashboard/audit-log',
    component: AuditLogFilterPage,
    requireAuth: true,
    breadcrumbName: t('auditLog.title'),
  },
  {
    path: '/dashboard/audit-log-result',
    component: AuditLogResultPage,
    requireAuth: true,
    breadcrumbName: t('auditLog.output'),
  },
  {
    path: '/dashboard/profile/:userId',
    component: EditProfile,
    requireAuth: true,
    breadcrumbName: t('user.editProfile.title'),
  },
  {
    path: '/dashboard/current-monitor/monitor-complete/:patientId/:monitorResultId',
    component: CompleteMonitorPage,
    requireAuth: true,
    breadcrumbName: t('monitor.title'),
  },
  {
    path: '/current-monitor/complete/:monitorResultId',
    component: CompleteMonitorPage,
  },
  {
    path: '/dashboard/current-monitor/screenerPage/:patientId/:monitorResultId',
    component: ConductMonitorPage,
    requireAuth: true,
    breadcrumbName: t('monitor.title'),
  },
  {
    path: '/current-monitor/screenerPage/:monitorResultId',
    component: ConductMonitorPage,
  },
  {
    path: '/monitor/:token/:tokenHash',
    component: PatientStartMonitorPage,
  },
  {
    path: '/interview/:token/:tokenHash',
    component: PatientStartInterviewPage,
  },
  {
    path: '/dashboard/patient/:patientId/:monitorResultId/resume-monitor',
    component: StartMonitorPage,
    requireAuth: true,
    breadcrumbName: t('monitor.title'),
  },
  {
    path: '/dashboard/patient/:patientId/:monitorResultId/start-monitor',
    component: StartMonitorPage,
    requireAuth: true,
    breadcrumbName: t('monitor.title'),
  },
  {
    path: '/dashboard/patient/:patientId/:interviewResultId/resume-interview',
    component: StartInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.title'),
  },
  {
    path: '/dashboard/patient/:patientId/:interviewResultId/start-interview',
    component: StartInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.title'),
  },
  {
    path: '/dashboard/current-interview/:reportType/:patientId/:interviewResultId/edit-interview',
    component: ConductInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.title'),
  },
  {
    // TODO: check why route constraint does not work
    // path: '/dashboard/current-interview/:reportType(question|screenerPage|ybocsPage)/:patientId/:interviewResultId',
    path: '/dashboard/current-interview/:reportType/:patientId/:interviewResultId',
    component: ConductInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.title'),
  },
  {
    // path: '/current-interview/:reportType(question|screenerPage|ybocsPage)/:interviewResultId',
    path: '/current-interview/:reportType/:interviewResultId',
    component: ConductInterviewPage,
  },
  {
    path: '/dashboard/current-interview/interview-complete/:patientId/:interviewResultId',
    component: CompleteInterviewPage,
    requireAuth: true,
    breadcrumbName: t('interview.title'),
  },
  {
    path: '/current-interview/complete/:interviewResultId',
    component: CompleteInterviewPage,
  },
  {
    path: '/current-interview/continue-next-interview/:reportType/:patientId/:interviewResultId',
    component: ContinueNextInterviewPage,
  },
  {
    path: '/current-interview/continue-next-interview/:reportType/:interviewResultId',
    component: ContinueNextInterviewPage,
  },
  {
    path: '/current-monitor/continue-next-monitor/:patientId/:trackerResultId',
    component: ContinueNextMonitorPage,
  },
  {
    path: '/current-monitor/continue-next-monitor/:trackerResultId',
    component: ContinueNextMonitorPage,
  },
  {
    path: '/dashboard/patient/:patientId/report-history/:interviewResultId',
    component: ReportHistoryPage,
    requireAuth: true,
    breadcrumbName: t('interviewReport.history.title'),
  },
  {
    path: '/dashboard/patient/:patientId/edit-schedule/:assessmentType/:assessmentTypeId',
    component: SchedulePage,
    requireAuth: true,
    breadcrumbName: t('schedule.title'),
  },
  {
    path: '/dashboard/patient/:patientId/follow-up',
    component: ClinicalFollowUpPage,
    requireAuth: true,
    breadcrumbName: t('clinicalFollowup.title'),
  },
  {
    path: '/dashboard/patient/:patientId/follow-up/:interviewResultId',
    component: ClinicalFollowUpPage,
    requireAuth: true,
    breadcrumbName: t('clinicalFollowup.title'),
  },
  {
    path: '/accounts/clinical-follow-ups/:clinicalAccountId/:reportName',
    component: ClinicalFollowUpsSignatureContenrPage,
    requireAuth: true,
    breadcrumbName: t('patientReport.title'),
  },
  {
    path: '/dashboard/patient/:patientId',
    component: PatientDashboard,
    requireAuth: true,
    breadcrumbName: t('patient.patientDashboard.title'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan',
    component: TreatmentPlanListPage,
    requireAuth: true,
    breadcrumbName: t('treatmentPlans.title'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan/add',
    component: AddOrEditTreatmentPlanPage,
    requireAuth: true,
    breadcrumbName: t('treatmentPlans.addBreadCrumb'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId',
    component: AddOrEditTreatmentPlanPage,
    requireAuth: true,
    breadcrumbName: t('treatmentPlans.editBreadCrumb'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan/report/:treatmentPlanId',
    component: TreatmentPlanReportPage,
    breadcrumbName: t('treatmentPlans.editBreadCrumb'),
  },
  {
    path: '/dashboard/edit-patient/:patientId',
    component: AddOrEditPatientPage,
    requireAuth: true,
    breadcrumbName: t('patient.editPatient.title'),
    showDashboardBreadcrumb: true,
  },
  {
    path: '/dashboard/add-patient',
    component: AddOrEditPatientPage,
    requireAuth: true,
    breadcrumbName: t('patient.addPatient.title'),
  },
  {
    path: '/dashboard/patients',
    component: PatientListPage,
    requireAuth: true,
    breadcrumbName: t('patient.patientList.title'),
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    requireAuth: true,
    breadcrumbName: t('dashboard.title'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId/goal/edit/:treatmentPlanGoalId',
    component: AddOrEditGoalPage,
    requireAuth: true,
    breadcrumbName: t('treatmentPlans.goalForm.edit'),
  },
  {
    path: '/dashboard/patient/:patientId/treatment-plan/edit/:treatmentPlanId/goal/add',
    component: AddOrEditGoalPage,
    requireAuth: true,
    breadcrumbName: t('treatmentPlans.goalForm.add'),
  },
  {
    path: '/dashboard/templates/edit-template/:templateId',
    component: AddOrEditTemplatePage,
    requireAuth: true,
    breadcrumbName: t('eSignature.template.edit'),
  },
  {
    path: '/dashboard/templates/add-template',
    component: AddOrEditTemplatePage,
    requireAuth: true,
    breadcrumbName: t('eSignature.template.add'),
  },
  {
    path: '/dashboard/cache-management',
    component: CacheManagementPage,
    requireAuth: true,
    breadcrumbName: t('cacheManagement.title'),
  },
  { path: '/login', component: LoginPage },
  { path: '/ehr-login', component: EhrLoginPage },
  { path: '/forgot-password', component: ForgotPasswordPage },
  { path: '/reset-password/:hash/:token', component: ResetPasswordPage },
  { path: '/mfa', component: MfaPage, requireAuth: true },
  { path: '/_redirect', component: ExternalRedirectPage },
  { path: '/', redirectTo: '/dashboard', breadcrumbName: t('site.home') },
  { path: '*', component: ErrorPage },
];

// ensure "parent" path comes first "child" path
// e.g. /dashboard -> /dashboard/child
export const getSortedRoutes: (t: TranslateFunction) => RouteConfigItem[] = (t) =>
  getRoutes(t).sort((a: RouteConfigItem, b: RouteConfigItem) => (a.path > b.path ? 1 : -1));
