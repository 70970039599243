import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { QueryCacheDuration, TagIds, TagTypes, buildMonitorUrl, downloadFile, rootApi } from '.';
import {
  AssessmentListRequestType,
  AssessmentListResponseType,
  EditMonitorTypeInfoRequestDto,
  EditAssessmentTypeInfoResponseDto,
} from '../../types/AssessmentBuilderType';
import {
  AssessmentScheduleRequest,
  AssessmentScheduleResponse,
} from '../../types/AssessmentSchedule';
import { MonitorStatsResponse } from '../../types/DashboardRequest';
import { AssessmentReportByTokenRequest } from '../../types/InterviewReportRequest';
import { CheckExpireTokenRequest, CheckExpireTokenResponse } from '../../types/InterviewRequest';
import {
  AssessmentTypesPagingRequest,
  AssessmentTypesPagingResponse,
  MonitorConductByTokenRequest,
  MonitorRequestType,
  MonitorResponseType,
  MonitorSettingsResponse,
  MonitorSubmissionByTokenRequest,
  MonitorSubmissionRequest,
  MonitorSubmissionResponse,
  MonitorTypeResponse,
  RequestMonitorType,
  monitorSettingsRequest,
} from '../../types/MonitorTypes';
import {
  FirstLastMonitorsRequest,
  FirstLastMonitorsResponse,
  LatestMonitorsRequest,
  LatestMonitorsResponse,
} from '../../types/PatientRequest';
import { ChangeMonitorModeToClinicianResponse } from '../../types/PatientType';
import { AssessmentReport } from '../../types/ReportType';

export const downloadMonitorReport = async (
  data: {
    monitorResultId: number;
  },
  accessToken: string,
  refreshToken: string
) => {
  return downloadFile(
    buildMonitorUrl('/v1/monitor-report/download'),
    data,
    accessToken,
    refreshToken
  );
};

// TODO: invalidate {type: TagTypes.LatestMonitors, patientId}
// cache when implement submitMonitorAnswers service;
// refer to buildInterviewSubmissionInvalidatingTags for caching logic

const buildMonitorSubmissionInvalidatingTags: ResultDescription<
  string,
  MonitorSubmissionResponse,
  MonitorSubmissionRequest,
  FetchBaseQueryError
> = (result, error, arg) =>
  error
    ? []
    : result?.success
    ? [
        {
          type: TagTypes.LatestMonitors,
          patientId: arg.patientId,
          id: result?.monitorTestId + '',
        },
        { type: TagTypes.LatestMonitors, patientId: arg.patientId },
        {
          type: TagTypes.LatestMonitorDetails,
          patientId: arg.patientId,
          id: result?.monitorTestId + '',
        },
      ]
    : [];

const api = rootApi
  .enhanceEndpoints({ addTagTypes: [TagTypes.LatestMonitors, TagTypes.LatestMonitorDetails] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMonitorStats: builder.query<MonitorStatsResponse, void>({
        query: () => ({
          url: buildMonitorUrl(`/v1/monitor/stats/`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getMonitorTypesData: builder.query<MonitorResponseType, MonitorRequestType>({
        query: ({ patientId }) => ({
          url: buildMonitorUrl(`/v1/monitor/monitor-types?patientId=${patientId}`),
          method: 'GET',
        }),
      }),

      getMonitorTypesByBaseTracker: builder.query<
        AssessmentTypesPagingResponse,
        AssessmentTypesPagingRequest
      >({
        query: (params) => ({
          url: buildMonitorUrl('/v1/monitor-builder/monitor-types-paging'),
          method: 'GET',
          params: params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      requestMonitor: builder.mutation<MonitorTypeResponse, RequestMonitorType>({
        query: (monitorInfo) => ({
          url: buildMonitorUrl('/v1/monitor/request-monitor'),
          method: 'POST',
          body: monitorInfo,
        }),
        invalidatesTags: (result, error, arg) =>
          error
            ? []
            : !arg.monitorTestId // create new
            ? [
                {
                  type: TagTypes.LatestMonitors,
                  patientId: arg.nViewPatientId,
                  id: arg.monitorTestId ? arg.monitorTestId + '' : TagIds.List,
                },
                {
                  type: TagTypes.LatestMonitors,
                  patientId: arg.nViewPatientId,
                },
              ]
            : [],
      }),

      requestMonitorByToken: builder.mutation<MonitorTypeResponse, MonitorConductByTokenRequest>({
        query: (monitorInfo) => {
          const { accessToken, ...body } = monitorInfo;
          return {
            url: buildMonitorUrl('/v1/monitor/request-patient-monitor'),
            method: 'POST',
            body,
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) =>
          error ? [] : [{ type: TagTypes.LatestMonitors, id: result?.monitorTestId + '' }],
      }),

      getLatestMonitors: builder.query<LatestMonitorsResponse, LatestMonitorsRequest>({
        query: ({ patientId, monitorTypeId, page, pageSize }) => ({
          url: buildMonitorUrl(
            `/v1/monitor/latest-monitors?patientId=${patientId}&monitorTypeId=${monitorTypeId}&page=${page}&pageSize=${pageSize}`
          ),
          method: 'GET',
        }),
        providesTags: (result) =>
          result?.items
            ? [
                ...result.items.map(({ id: monitorResultId }) => ({
                  type: TagTypes.LatestMonitors,
                  id: monitorResultId + '',
                })),
                {
                  type: TagTypes.LatestMonitors,
                  id: TagIds.List,
                },
              ]
            : [
                {
                  type: TagTypes.LatestMonitors,
                  id: TagIds.List,
                },
              ],
      }),

      getLatestMonitorsByIds: builder.query<LatestMonitorsResponse, LatestMonitorsRequest>({
        query: ({ patientId, page, pageSize, monitorResultIds }) => ({
          url: buildMonitorUrl(`/v1/monitor/latest-monitors`),
          params: {
            patientId,
            page,
            pageSize,
            monitorResultIds: monitorResultIds?.join(',') || undefined,
          },
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.Short,
        providesTags: (result, error, arg) =>
          result?.items
            ? [
                ...result.items.map(({ id: monitorResultId }) => ({
                  type: TagTypes.LatestMonitorDetails,
                  patientId: arg.patientId,
                  id: monitorResultId + '',
                })),
              ]
            : [],
      }),

      getMonitorSettings: builder.query<MonitorSettingsResponse, monitorSettingsRequest>({
        query: (request) => ({
          url: buildMonitorUrl('/v1/monitor/settings'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.MonitorSettings,
      }),

      getPatientMonitorSettings: builder.query<MonitorSettingsResponse, monitorSettingsRequest>({
        query: ({ accessToken, ...body }) => ({
          url: buildMonitorUrl('/v1/monitor/patient-settings'),
          method: 'GET',
          params: { ...body },
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.MonitorSettings,
      }),

      monitorCheckExpireToken: builder.mutation<CheckExpireTokenResponse, CheckExpireTokenRequest>({
        query: (tokenRequest) => ({
          url: buildMonitorUrl('/v1/monitor/check-expire-token'),
          method: 'POST',
          body: tokenRequest,
        }),
      }),

      submitMonitorAnswers: builder.mutation<MonitorSubmissionResponse, MonitorSubmissionRequest>({
        // exclude patientId from request
        query: ({ patientId, ...monitorInfo }) => ({
          url: buildMonitorUrl('/v1/monitor/submit-monitor-answers-internal'),
          method: 'POST',
          body: monitorInfo,
        }),
        invalidatesTags: buildMonitorSubmissionInvalidatingTags,
      }),

      submitMonitorAnswersByToken: builder.mutation<
        MonitorSubmissionResponse,
        MonitorSubmissionByTokenRequest
      >({
        // exclude patientId from request
        query: (monitorInfo) => {
          const { accessToken, patientId, ...body } = monitorInfo;
          return {
            url: buildMonitorUrl('/v1/monitor/submit-patient-monitor-answers'),
            method: 'POST',
            body,
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          };
        },
        invalidatesTags: buildMonitorSubmissionInvalidatingTags,
      }),

      getMonitorSchedule: builder.query<AssessmentScheduleResponse, AssessmentScheduleRequest>({
        query: (request) => ({
          url: buildMonitorUrl('/v1/monitor/get-monitor-schedule'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.Short,
      }),

      getFirstAndLastTrackers: builder.query<FirstLastMonitorsResponse, FirstLastMonitorsRequest>({
        query: (request) => ({
          url: buildMonitorUrl('/v1/monitor/first-last-monitors'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getMonitorReportByToken: builder.query<AssessmentReport, AssessmentReportByTokenRequest>({
        query: (params) => ({
          url: buildMonitorUrl('/v1/monitor-report/clinician-report'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getMonitorList: builder.query<AssessmentListResponseType, AssessmentListRequestType>({
        query: (params) => ({
          url: buildMonitorUrl('/v1/monitor-builder/monitors'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getMonitorTypeInfo: builder.query<
        EditAssessmentTypeInfoResponseDto,
        EditMonitorTypeInfoRequestDto
      >({
        query: (params) => ({
          url: buildMonitorUrl('/v1/monitor-builder/edit-monitor-type-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addOrEditMonitorType: builder.mutation<any, any>({
        query: (body) => ({
          url: buildMonitorUrl('/v1/monitor-builder/add-edit-monitor-type'),
          method: 'POST',
          body,
        }),
      }),

      sendMonitorEmailOrTextTheQuestions: builder.mutation<void, { trackerResultId: number }>({
        query: (body) => ({
          url: buildMonitorUrl('/v1/monitor/monitor-send-notification-to-patient'),
          method: 'POST',
          body,
        }),
      }),

      changeMonitorModeToClinician: builder.mutation<
        ChangeMonitorModeToClinicianResponse,
        { trackerResultId: number }
      >({
        query: (body) => ({
          url: buildMonitorUrl('/v1/monitor/monitor-change-mode-to-clinician'),
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const {
  useGetMonitorStatsQuery,
  useGetMonitorTypesDataQuery,
  useGetMonitorTypesByBaseTrackerQuery,
  useRequestMonitorMutation,
  useRequestMonitorByTokenMutation,
  useMonitorCheckExpireTokenMutation,
  useGetLatestMonitorsQuery,
  useGetMonitorSettingsQuery,
  useGetPatientMonitorSettingsQuery,
  useSubmitMonitorAnswersMutation,
  useSubmitMonitorAnswersByTokenMutation,
  useGetMonitorScheduleQuery,
  useGetFirstAndLastTrackersQuery,
  useGetMonitorReportByTokenQuery,
  useGetLatestMonitorsByIdsQuery,
  useGetMonitorListQuery,
  useGetMonitorTypeInfoQuery,
  useAddOrEditMonitorTypeMutation,
  useSendMonitorEmailOrTextTheQuestionsMutation,
  useChangeMonitorModeToClinicianMutation,
} = api;

export const { endpoints: monitorApis } = api;
