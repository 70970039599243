import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface Props {
  sortField: string;
  sortDir: string;
  onSorted: (field: string, dir: string) => void;
  sx?: SxProps;
  isUser?: boolean;
  isSite?: boolean;
  isAccount?: boolean;
  isSso?: boolean;
  isTreatmentPlan?: boolean;
  isTemplate?: boolean;
  isPatient?: boolean;
}

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  },

  '& .MuiRadio-root': {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '10px',
  },

  '& .MuiDivider-root': {
    margin: '8px 0',
  },
}));

const StyledSortHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 16px',
});

const StyledSortLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
}));

const StyledSortButton = styled(Button)({
  textTransform: 'unset',
  width: '70px',
});

export default function PatientSortMenu({
  sortField,
  sortDir,
  onSorted,
  sx,
  isUser,
  isSite,
  isAccount,
  isSso,
  isTreatmentPlan,
  isTemplate,
  isPatient,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const id = 'sort';
  const [sortOptions, setSortOptions] = useState<{ sortField: string; sortDir: string }>({
    sortField,
    sortDir,
  });

  const getSortStyle = (dir: string) =>
    dir === sortOptions.sortDir ? { color: theme.palette.primary.main } : {};

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSortOptions({ sortField, sortDir }); // reset to latest selection in parent component
  };

  const onSortFieldChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSortOptions({ ...sortOptions, sortField: value });
  };

  const changeSortDir = (dir: string) => {
    setSortOptions({ ...sortOptions, sortDir: dir });
  };

  const applySort = (event: React.MouseEvent) => {
    onSorted(sortOptions.sortField, sortOptions.sortDir);
    handleClose();
    event.preventDefault();
  };

  const renderSortLabel = () => {
    let labelContent = '';

    if (isSite) {
      labelContent = t('clinicSite.manageSites.sortName');
    } else if (isUser) {
      labelContent = t('user.manageUsers.userSort.sortName');
    } else if (isAccount) {
      labelContent = t('account.accountSort.sortName');
    } else if (isSso) {
      labelContent = t('ssoClient.manageSsoClient.sortName');
    } else if (isTemplate) {
      labelContent = t('eSignature.manageTemplateList.sortName');
    } else if (isTreatmentPlan) {
      labelContent = `${t('treatmentPlans.manageTreatmentPlan.sortName')}`;
    } else {
      labelContent = t('patient.patientList.menu.patientName');
    }

    return labelContent;
  };

  return (
    <>
      <StyledSortButton
        id={`${id}-button`}
        aria-label={`${id}-table-menu`}
        aria-controls={`${id}-menu`}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        startIcon={<FilterListIcon />}
        sx={{ ...sx }}
      >
        <Typography>{t('patient.patientList.sort')}</Typography>
      </StyledSortButton>
      <StyledMenu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
        }}
        anchorOrigin={{ vertical: 60, horizontal: 0 }}
      >
        <StyledSortHeader>
          <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
            {t('patient.patientList.menu.sortBy')}
          </Typography>
          <StyledSortLink href="#" onClick={applySort}>
            {t('patient.patientList.menu.done')}
          </StyledSortLink>
        </StyledSortHeader>
        <RadioGroup
          value={sortOptions.sortField}
          name="sortby-buttons-group"
          onChange={onSortFieldChanged}
        >
          <MenuItem>
            <FormControlLabel value="name" control={<Radio />} label={renderSortLabel()} />
          </MenuItem>
          {!isAccount && !isSso && !isTreatmentPlan && !isTemplate && (
            <MenuItem>
              <FormControlLabel
                value="account"
                control={<Radio />}
                label={t('patient.patientList.menu.account').toString()}
              />
            </MenuItem>
          )}
          {!(isSite || isAccount || isSso || isTreatmentPlan || isTemplate) && (
            <MenuItem>
              <FormControlLabel
                value="site"
                control={<Radio />}
                label={t('patient.patientList.menu.site').toString()}
              />
            </MenuItem>
          )}
          {isSso && (
            <>
              <MenuItem>
                <FormControlLabel
                  value="description"
                  control={<Radio />}
                  label={t('ssoClient.manageSsoClient.tableHeader.description').toString()}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  value="protocol"
                  control={<Radio />}
                  label={t('ssoClient.manageSsoClient.tableHeader.protocol').toString()}
                />
              </MenuItem>
            </>
          )}
          {isTreatmentPlan && (
            <>
              <MenuItem>
                <FormControlLabel
                  value="startDate"
                  control={<Radio />}
                  label={t('treatmentPlans.manageTreatmentPlan.tableHeader.startDate').toString()}
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  value="status"
                  control={<Radio />}
                  label={t('treatmentPlans.manageTreatmentPlan.tableHeader.status').toString()}
                />
              </MenuItem>
            </>
          )}
          {isPatient && (
            <MenuItem>
              <FormControlLabel
                value="status"
                control={<Radio />}
                label={t('patient.patientList.menu.complete').toString()}
              />
            </MenuItem>
          )}
        </RadioGroup>
        <Divider />
        <MenuItem onClick={() => changeSortDir('asc')}>
          <ListItemIcon>
            <ArrowDropUpIcon sx={getSortStyle('asc')} />
          </ListItemIcon>
          <ListItemText sx={getSortStyle('asc')}>
            {t('patient.patientList.menu.ascending')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => changeSortDir('desc')}>
          <ListItemIcon>
            <ArrowDropDownIcon sx={getSortStyle('desc')} />
          </ListItemIcon>
          <ListItemText sx={getSortStyle('desc')}>
            {t('patient.patientList.menu.descending')}
          </ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  );
}
