import { AssessmentStatus } from '../common/utils/assessmentStatus';
import { ClinicAccountItems } from './ClinicAccountType';
import { InterviewFormatDto } from './InterviewQuestionTypes';
import { PatientDiagnosisInfo, PatientInfo } from './PatientRequest';

export interface PatientInfoCardProps {
  data?: PatientInfo;
  clinicAccounts?: ClinicAccountItems[];
  patientIdOnly?: boolean;
  interview?: InterviewFormatDto;
  showDiagnosis?: boolean;
  diagnosisData?: PatientDiagnosisInfo[];
  onClickEditPatient?: () => void;
  onClickEditFeedback?: () => void;
}

export enum MeasurementPacketType {
  IntakePacket = 'intakePacket',
  MeasurementPacket = 'measurementPacket',
}

export interface IntakePacketPopupBodyProp {
  setChoseOption: (data?: IntakePopupType) => void;
  hasEmailOrPhoneNumber?: boolean;
}

export interface ChangeInterviewModeToClinicianResponse {
  newInterviewResultId?: number;
}

export interface ChangeMonitorModeToClinicianResponse {
  newTrackerResultId?: number;
}

export interface CheckShowPopupIntakePacketResponse {
  interviewResultId?: number;
  interviewTypeId?: number;
  trackerResultId?: number;
  trackerTypeId?: number;
  type?: MeasurementPacketType;
  trial?: boolean;
  statusData?: AssessmentStatus;
}

export enum IntakePopupType {
  EmailAndTextTheQuestionsToThePatient = 'emailAndTextTheQuestionsToThePatient',
  PatientWillFillOutTheQuestions = 'patientWillFillOutTheQuestions',
  DoNotSendTheIntakePacket = 'doNotSendTheIntakePacket',
}
