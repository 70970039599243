import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tab from '@mui/material/Tab';
import { tableCellClasses } from '@mui/material/TableCell';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetInterviewListQuery } from '../../app/services/interview';
import { useGetMonitorListQuery } from '../../app/services/monitor';
import CustomPagination from '../../common/components/CustomPagination';
import DataList from '../../common/components/DataList';
import DataTable from '../../common/components/DataTable';
import Loading from '../../common/components/Loading';
import PageHeader from '../../common/components/PageHeader';
import RoundedContainer from '../../common/components/RoundedContainer';
import NoteIcon from '../../common/components/icons/NoteIcon';
import DashboardLayout from '../../common/layouts/Dashboard';
import { AssessmentBuilder } from '../../common/utils/assessmentType';
import { DefaultPageSize } from '../../common/utils/commonUtils';
import { getPageTitle } from '../../common/utils/pageUtils';
import { SortDirs } from '../../common/utils/sortInfo';
import { toTitleCase } from '../../common/utils/stringUtils';
import {
  Assessment,
  AssessmentListRequestType,
  AssessmentListResponseType,
} from '../../types/AssessmentBuilderType';
import { PageProps } from '../../types/PageProps';
import ErrorPage from '../error/ErrorPage';
import ContextMenu from '../patient/ContextMenu';

const StyledSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  columnGap: '8px',
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    width: 'auto',
    flexBasis: '40%',
    maxWidth: '1200px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '16px',
  },
}));

const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.xPalette.white,
  flexBasis: '100%',
  minWidth: '240px',
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'unset',
    width: '74%',
    minWidth: 'unset',
  },
}));

type SearchParamsType = {
  search?: string;
  type?: string;
  page?: string;
  sortField?: string;
  sortDir?: string;
};

const TabTypeMap: { [key: number]: string } = {
  0: AssessmentBuilder.INTERVIEW,
  1: AssessmentBuilder.MONITOR,
};

export default function AssessmentBuilderBuilderPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const searchTextboxRef = useRef<HTMLInputElement>(null);

  const searchOptions = {
    search: searchParams.get('search') || '',
    type: searchParams.get('type') || AssessmentBuilder.INTERVIEW,
    page: searchParams.get('page') || '1',
    sortField: searchParams.get('sort') || 'name',
    sortDir: searchParams.get('dir') || SortDirs.Asc,
  };

  const { data, isLoading, error } = useChangeInterviewOrMonitor(searchOptions);

  const updateSearchUrl = (updateParams: SearchParamsType) => {
    const params: SearchParamsType = {
      ...searchOptions,
      ...updateParams,
    };
    const url = generatePath(
      '/dashboard/assessment-builder/' +
        '?search=:search' +
        '&type=:type' +
        '&page=:page' +
        '&sort=:sortField' +
        '&dir=:sortDir',
      params
    );

    if (params?.type === AssessmentBuilder.INTERVIEW) {
      setTabIndex(0);
    } else if (params?.type === 'monitor') {
      setTabIndex(1);
    }

    navigate(url, { replace: true });
  };

  const handleTabChange = (e: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    if (searchTextboxRef?.current) {
      searchTextboxRef.current.value = '';
    }
    updateSearchUrl({
      search: '',
      page: String(1),
      type: TabTypeMap[newIndex] || AssessmentBuilder.INTERVIEW,
    });
  };

  const handleSearch = () => {
    const input = searchTextboxRef?.current?.value;
    updateSearchUrl({ page: String(1), search: input || '' });
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleTableSorting = () => {
    const newDir = searchOptions.sortDir === SortDirs.Asc ? SortDirs.Desc : SortDirs.Asc;
    updateSearchUrl({ sortDir: newDir });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const index = Object.values(TabTypeMap).indexOf(
      searchOptions?.type || AssessmentBuilder.INTERVIEW
    );
    if (index > 0) {
      setTabIndex(Number(index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContextMenu = (item: Assessment, tabIndex: number) => {
    return (
      <ContextMenu
        id={`interview-or-monitor-${item.id}`}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        menuItems={[
          {
            isGroupHeader: true,
            label:
              TabTypeMap[tabIndex] === AssessmentBuilder.INTERVIEW
                ? t('assessmentBuilder.menuContext.interviewsBuilder')
                : t('assessmentBuilder.menuContext.monitorsBuilder'),
          },
          {
            isDisplayed: true,
            label:
              TabTypeMap[tabIndex] === AssessmentBuilder.INTERVIEW
                ? t('assessmentBuilder.menuContext.interviewTypes')
                : t('assessmentBuilder.menuContext.monitorTypes'),
            icon: <NoteIcon sx={{ '& path': { fill: 'white' } }} />,
            onClick: () => {
              navigate(
                TabTypeMap[tabIndex] === AssessmentBuilder.INTERVIEW
                  ? `/dashboard/assessment-builder/interview/${item.id}/interview-types`
                  : `/dashboard/assessment-builder/monitor/${item.id}/monitor-types`
              );
            },
          },
        ]}
      />
    );
  };

  const renderDesktopData = (tabIndex: number, data?: Assessment[]) => {
    return (
      <DataTable
        sort={{ field: searchOptions.sortField, dir: searchOptions.sortDir }}
        onSorted={handleTableSorting}
        commonCellStyles={{
          [`&.${tableCellClasses.head}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
          [`&.${tableCellClasses.body}`]: {
            padding: '16px 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
          },
        }}
        headers={[
          { text: t('assessmentBuilder.list.header.name'), sx: { width: '60%' }, sortKey: 'name' },
          { text: t('assessmentBuilder.list.header.category'), sx: { width: '20%' } },
          { text: t('assessmentBuilder.list.header.status'), sx: { width: '10%' } },
          {
            text: t('assessmentBuilder.list.header.action'),
            sx: { width: '10%', textAlign: 'center' },
          },
        ]}
        items={
          data?.map((item: Assessment) => [
            {
              component: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>{`${item?.name} (${item?.language?.toUpperCase()})`}</Typography>
                </Box>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {item?.interviewCategory?.name || 'Monitor'}
                </Typography>
              ),
            },
            {
              component: (
                <Typography fontWeight={400} textAlign="left">
                  {toTitleCase(item?.status || '')}
                </Typography>
              ),
            },
            {
              component: renderContextMenu(item, tabIndex),
              sx: { textAlign: 'center' },
            },
          ]) || []
        }
      />
    );
  };

  const renderPhoneData = (tabIndex: number, data?: Assessment[]) => {
    return (
      <DataList
        lineHeight={'auto'}
        data={
          data?.map((item: Assessment) => ({
            leftHeader: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{item?.name}</Typography>
              </Box>
            ),
            rightHeader: renderContextMenu(item, tabIndex),
            items: [[t('assessmentBuilder.list.header.status'), item.status]],
          })) || []
        }
      />
    );
  };

  if (error) {
    return <ErrorPage statusCode={(error as FetchBaseQueryError).status} />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>{getPageTitle(t('assessmentBuilder.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: matches ? 2 : 3,
          }}
        >
          <PageHeader headerText={t('assessmentBuilder.title')} backUrl="/dashboard" />
          <StyledSearchWrapper>
            <StyledSearchBox
              name="assessment-search-field"
              inputRef={searchTextboxRef}
              onKeyUp={handleSearchKeyUp}
              defaultValue={searchOptions.search}
              placeholder={
                TabTypeMap[tabIndex] === AssessmentBuilder.INTERVIEW
                  ? t('assessmentBuilder.searchInterviews')
                  : t('assessmentBuilder.searchMonitors')
              }
              endAdornment={
                <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              }
            />
          </StyledSearchWrapper>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box sx={{ borderBottom: matches ? 0 : 1, borderColor: matches ? 'unset' : 'divider' }}>
              <Tabs
                sx={{
                  width: '40%',
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="assessmentBuilder-list-tabs"
              >
                <Tab
                  sx={{ width: '50%', fontWeight: 500 }}
                  label={t('assessmentBuilder.list.interviews')}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <Tab
                  sx={{ width: '50%', fontWeight: 500 }}
                  label={t('assessmentBuilder.list.monitors')}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </Tabs>
            </Box>
            {data?.assessments?.length === 0 ? (
              <RoundedContainer sx={{ mt: 2, p: 2 }}>
                <Typography>
                  {t(`assessmentBuilder.list.errors.assessmentsNotFound`, {
                    assessmentType:
                      TabTypeMap[tabIndex] === AssessmentBuilder.INTERVIEW
                        ? AssessmentBuilder.INTERVIEW
                        : AssessmentBuilder.MONITOR,
                  })}
                </Typography>
              </RoundedContainer>
            ) : (
              <>
                <Box>
                  {matches
                    ? renderPhoneData(tabIndex, data?.assessments)
                    : renderDesktopData(tabIndex, data?.assessments)}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    py: matches ? 4 : 2,
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    background: theme.xPalette.white,
                  }}
                >
                  <CustomPagination
                    changePage={(e, value) => {
                      updateSearchUrl({ page: value });
                    }}
                    currentPage={Number(searchOptions?.page)}
                    pages={data?.totalPages || 0}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
}

const useChangeInterviewOrMonitor = (
  searchOptions: SearchParamsType
): {
  data?: AssessmentListResponseType;
  isLoading?: boolean;
  error?: FetchBaseQueryError | SerializedError;
} => {
  const submitParams = {
    search: searchOptions?.search,
    page: searchOptions?.page,
    pageSize: `${DefaultPageSize}`,
    sort: `${searchOptions?.sortField} ${searchOptions?.sortDir}`,
  } as AssessmentListRequestType;

  const isInterview = searchOptions?.type === AssessmentBuilder.INTERVIEW;
  const isMonitor = searchOptions?.type === AssessmentBuilder.MONITOR;

  const {
    data: interviews,
    isLoading: isLoadingInterviews,
    error: interviewError,
  } = useGetInterviewListQuery(isInterview ? submitParams : skipToken);

  const {
    data: monitors,
    isLoading: isLoadingMonitors,
    error: monitorError,
  } = useGetMonitorListQuery(isMonitor ? submitParams : skipToken);

  return {
    data: isInterview ? interviews : monitors,
    isLoading: isLoadingInterviews || isLoadingMonitors,
    error: interviewError || monitorError,
  };
};
