import { buildAccountUrl, QueryCacheDuration, rootApi, TagIds, TagTypes } from '.';
import {
  AuditLogClinicSiteRequest,
  AuditLogClinicSiteResponseDto,
  ChangeStatusSiteRequest,
  ChangeStatusSiteResponse,
  ClinicSiteAddRequest,
  ClinicSiteAddResponse,
  ClinicSiteDetail,
  ClinicSiteEditRequest,
  ClinicSiteEditResponse,
  ClinicSiteListRequest,
  ClinicSiteListResponse,
  ClinicSiteResponse,
} from '../../types/ClinicSiteType';

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [TagTypes.Site],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getClinicSites: builder.query<ClinicSiteResponse, void>({
        query: () => ({
          url: buildAccountUrl(`/v1/account/site/get-sites`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getSiteList: builder.query<ClinicSiteListResponse, ClinicSiteListRequest>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/site/list-sites`),
          method: 'GET',
          params,
        }),
        providesTags: (result) =>
          result?.clinicSites
            ? [
                ...result.clinicSites.map(({ id }) => ({
                  type: TagTypes.Site,
                  id: id + '',
                })),
                {
                  type: TagTypes.Site,
                  id: TagIds.List,
                },
              ]
            : [],
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      activateOrDeactivateSite: builder.mutation<ChangeStatusSiteResponse, ChangeStatusSiteRequest>(
        {
          query: (body) => ({
            url: buildAccountUrl(`/v1/account/site/update-status`),
            method: 'POST',
            body,
          }),
          invalidatesTags: (_result, error, arg) =>
            error ? [] : [{ type: TagTypes.Site, id: arg.siteId }],
        }
      ),

      getSiteInfo: builder.query<ClinicSiteDetail, { siteId: number }>({
        query: (params) => ({
          url: buildAccountUrl(`/v1/account/site`),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addSite: builder.mutation<ClinicSiteAddResponse, ClinicSiteAddRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/site/create-site`),
          method: 'POST',
          body,
        }),
      }),
      editSite: builder.mutation<ClinicSiteEditResponse, ClinicSiteEditRequest>({
        query: (body) => ({
          url: buildAccountUrl(`/v1/account/site/edit-site`),
          method: 'POST',
          body,
        }),
      }),
      getAuditLogSites: builder.query<AuditLogClinicSiteResponseDto, AuditLogClinicSiteRequest>({
        query: (params) => ({
          url: buildAccountUrl('/v1/account/site/audit-log-sites'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetSiteListQuery,
  useGetSiteInfoQuery,
  useGetClinicSitesQuery,
  useActivateOrDeactivateSiteMutation,
  useAddSiteMutation,
  useEditSiteMutation,
  useGetAuditLogSitesQuery,
} = api;
