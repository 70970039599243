import dayjs from 'dayjs';
import { AssessmentMode } from './assessmentMode';

export const endDateValidation = ({
  assessmentMode,
  interval,
  timezone,
  startDate,
  endDate,
}: {
  assessmentMode: string;
  interval: number;
  timezone?: string;
  startDate?: any;
  endDate: any;
}) => {
  let suggestDate = '';
  if (assessmentMode === AssessmentMode.Email && interval > 0) {
    const currentStartDate = new Date(dayjs(startDate).tz(timezone).format().split('T')[0]);
    let newDate = 0;
    switch (interval) {
      case 1:
        newDate = new Date(currentStartDate.setDate(currentStartDate.getDate() + 1)).getTime();
        break;
      case 2:
        newDate = new Date(currentStartDate.setDate(currentStartDate.getDate() + 7)).getTime();
        break;
      case 3:
        newDate = new Date(currentStartDate.setDate(currentStartDate.getDate() + 14)).getTime();
        break;
      case 4:
        newDate = new Date(currentStartDate.setMonth(currentStartDate.getMonth() + 1)).getTime();
        break;
    }
    const endDateNewFormat = new Date(dayjs(endDate).tz(timezone).format().split('T')[0]);
    if (endDateNewFormat.getTime() < newDate) {
      const dd = String(currentStartDate.getDate()).padStart(2, '0');
      const mm = String(currentStartDate.getMonth() + 1).padStart(2, '0');
      const yyyy = currentStartDate.getFullYear();
      suggestDate = `${mm}/${dd}/${yyyy}`;
    }
  }
  return suggestDate;
};
